import ModelBase from "./shared/model-base.js";

const OPTIONS = {
  columns: ["id", "name", "ori_pano_image", "images"],
};

export default class ObjVrScene extends ModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes);
  }
}
